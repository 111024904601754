
.ServiceData {
    margin-top: 30px;
}

.ServiceData div {
    /*padding: 0px 4px;*/
}

.ServiceCaptcha {
    color: #79787F;
    font-weight: bold;
    font-size: 15px;
    text-align: left;
}

.Break {
    flex-basis: 100%;
    height: 0;
}

.OfficeService {
    display: flex;
    flex-direction: column;
    width: 40%;

    margin-top: 40px;

}

.OfficeGeneralService {
    color: #79787F;
    font-weight: bold;
    font-size: 15px;
}


.Paragraph {
    text-align: left;
    color: #79787F;
    font-weight: bold;
    font-size: 15px;
}

.OfficeService label {
    display: none !important;
}


.OfficeService li {
    padding: 0px !important;
}

.CarpetService {
    display: flex;
    flex-direction: column;
    width: 40%;
}

@media only screen and (max-width: 600px) {

    .CarpetService,
    .OfficeService {
        width: 90%;
    }
}

.ServiceData {
    margin-top: 30px;
}
.CarpetService label {
    display: none !important;

}

.CarpetService li {
    padding: 0px !important;

}

.Instruction {
    font-size: 9px !important;
    line-height: inherit;
    clear: both;
    font-family: inherit;
    letter-spacing: normal;
}

.ErrorMessage {
    font-size: 9px !important;
    font-weight: normal !important;
    line-height: inherit;
    clear: both;
    font-family: inherit;
    letter-spacing: normal;
}