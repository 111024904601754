.Input {
    flex: 0 50%;
    /*width: 400px;*/
    /*padding: 10px*/
}

.Input ul {
    /*width: 100%;*/
    text-decoration: none;
    padding: 0;
    list-style-type: none;

}

.Input ul li {
    display: block;
    text-decoration: none;

}
@media only screen and (min-width: 600px) {
    .Input ul li {
    padding: 0 15px;
    }
  }

.Input ul li span {
    color: darkred;
}

.Input ul li label {
    font-weight: bolder;
    font-size: 16px;
    font-family: Roboto, sans-serif;
    text-align: left;
    display: block;
    color: #79787F;
}

.Input ul li input {
    margin: 10px auto;
    width: 100%;
    height: 30px;
    border: 1px solid #dcdcdc;
}


.Input ul li input:focus {
    border: 1px solid #333;
    box-shadow: 0 5px 32px rgba(103, 122, 131, 0.17);
    -webkit-box-shadow: 0 5px 32px rgba(103, 122, 131, 0.17);
    -moz-box-shadow: 0 5px 32px rgba(103, 122, 131, 0.17);
}

.Input ul li input.visited {
    border: 1px solid #333;
    box-shadow: 0 5px 32px rgba(103, 122, 131, 0.17);
    -webkit-box-shadow: 0 5px 32px rgba(103, 122, 131, 0.17);
    -moz-box-shadow: 0 5px 32px rgba(103, 122, 131, 0.17);
}
