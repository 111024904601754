.Container {
    /*margin-top: 50px ;*/
    max-width: 1100px;
    margin: 50px  auto 0 auto;
}

.LogoMobile {
    display: none;
}
.Steps {
    width: 30%;
    margin: 0 auto;


}
.Divider {
    width: 50%;
}
@media (max-width: 700px) {
    .LogoMobile {
        text-align: center;
        display: block;
        margin: 10px auto;
    }

    .LogoMobile img {
        width: 120px;

        height: 60px
    }
    .Container {
        /*margin-top: 50px ;*/
       padding-left: 20px;
    }
}

/*.Buttons a {*/
/*    border-radius: 5px;*/
/*    color: white;*/
/*    text-decoration: none;*/
/*    text-align: center;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    font-size: 16px;*/
/*}*/

/*.ButtonOferte:hover {*/
/*    background-color: forestgreen;*/
/*    color: white;*/
/*    text-decoration: none;*/
/*    cursor: pointer;*/
/*}*/

/*.ButtonOferte {*/
/*    background-color: green;*/
/*    width: 100px;*/
/*}*/

/*.ButtonContracte {*/
/*    background-color: darkslateblue;*/
/*    width: 100px;*/
/*}*/

/*.ButtonContracte:hover {*/
/*    background-color: darkslateblue;*/
/*    opacity: 0.9;*/
/*    color: white;*/
/*    text-decoration: none;*/
/*    cursor: pointer;*/
/*}*/