
.Login {
    max-width: 400px;
}
.Login h1,h2,h3,h4 {
    text-align: center;
}
.Login button{
   margin: auto;
}




@media only screen and  (min-width:870px){
    .Login {
        max-width: 400px;
        margin: 200px auto;
    }
}