@media only screen and (max-width: 600px) {
    .OfferSend Button{
        width:70%;
        margin-top:10px;
        margin-left:0px !important;
    }
    .Spinners {
        margin-left: '200px';
         margin-top: '-40px';
    }
  }

.Spinners {
    margin-left: '385px';
     margin-top: '-40px';
}