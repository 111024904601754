
.Main{
    display: flex;
    flex-direction: column;
    /*max-width: 1400px;*/
}

.Review {
    width: 80%;
    margin: 20px 0 auto 0;

}
.ContactData {
    width: 80%;
    margin: 20px 0 auto 0;
}



.ContactDataForm {
    /* display: flex;
    flex-flow: wrap;
    width: 100%;
     */
    /*padding: 10px;*/
    width: 100%;
    justify-content: space-between;
    margin: 40px 0 auto 0;
    align-items: center;
}


.ContactDataParagraph {
    /*text-align: l;*/
    color: #333333;
    font-weight: 700;
    font-size: 1.25em;
}

@media only screen and (min-width: 600px) {
    .ContactDataForm {
        /*display: flex;*/
        /*flex-flow: wrap;*/
        /*flex-direction: column ;*/
        width: 100%;
        /*padding: 10px;*/
        justify-content: space-between;
        margin: 40px 0 auto 0;
        text-align: left;
        align-items: center;
    }
    .NextStep Button {
        margin-left:15px;
    }

    .Main{
        flex-direction: row;
    }

    .ContactData {
        width: 60%;
        margin: 20px 0 auto 0;
        /*text-align: center;*/
        /*align-items: center;*/
    }
    .ContactData hr {
        margin-left: -10px;
        /*text-align: center;*/
        /*align-items: center;*/
    }
    .Review {
        width: 40%;
        margin: 20px auto;
        /*text-align: center;*/
        /*align-items: center;*/
    }
  }

  @media only screen and (max-width: 600px) {
    .PreviousStep Button{
        width:70%;
        margin-top:10px;
        margin-left:0px !important;
    }
    .NextStep{
        text-align: left !important;
    }
  }