.Footer {
    width: 100%;
    /*height: 400px;*/
    background-color: #f1f2f3;

}
.FooterWidth  {
    padding-top: 30px;

    margin: 70px auto 70px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    max-width: 1200px;
}

@media only screen and  (max-width:870px){
    .Footer {
        height: 100%;
    }
    .FooterWidth{
        flex-wrap: nowrap;
        flex-direction: column;
        margin: 70px auto 0 auto;
    }
}

@media only screen and  (min-width:870px){
    .Footer {
        /*height: 100%;*/
        /*position: absolute;*/
        bottom: 0;
    }
    .FooterWidth{
        margin: 70px auto 0 auto;
        padding-top: 30px;
    }
}