.Button {
    padding: 17px 30px;
    /*font-size: 90% !important;*/
    font-size: 15px !important;
    font-family: Montserrat, sans-serif;
    line-height: 2px;
    font-weight: bold;
    text-transform: uppercase;
    /*margin-left: 0px;*/
    cursor: pointer;
}

.Continue {
    background-color: #053889;
    color: #ffffff;
    border: 1px solid #053889;
}
.Success {
    background-color: #02B201;
    border: 1px solid #02B201;
    color: #ffffff;

}

.Button:disabled,
.Button[disabled] {
    border: 1px solid #999999;
    background-color: darkred;
    color: white;
    cursor: not-allowed;
}