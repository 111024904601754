.Menu{
   padding-left: 50px;
    width:350px;

}
.Menu ul{
    list-style: none;
    padding-left:0;
}
.Menu li{
   margin: 5px 0;
}
.Menu i{
    margin-right: 10px;
    color:#4AB8E3;
    font-size:14px;
 }
 .Menu a{
    line-height: 1.643;
    font-family: Roboto, sans-serif;
    color:#677d8f;
    text-decoration: none; 
    font-size:14px;
    font-weight: normal;

 }


@media only screen and  (max-width:870px){
   .Menu{
      padding-left: 30px ;
      padding-bottom: 30px;
   }
  
}