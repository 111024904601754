.Nav {
    display: inline-block;
    padding: 30px;
    width: 50%;
}

 .Link {
    padding: 10px 14px;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    line-height: 2em;
    text-decoration: none;
    letter-spacing: 0.04em;
    color: #1e1d24;
    font-weight: 700;
    font-size: 13px !important;
}

.Link:hover {
    color: #08388a !important;
    /*color: red !important;*/
    font-weight: bolder;
}

@media (max-width: 700px) {

    .Nav {
        display: none;
    }
    .dropdownContent {
        margin-top: 0 !important;
    }
    .dropdownContent a:hover {
        background-color: #ddd !important;
        font-weight: bold !important;
        color: #08388a !important;
    }
    .dropdownContent a {
        font-size: 11px !important;
        padding :10px 10px;
        background-color: white !important;
        color: black !important ;
        font-weight: bold !important;

    }
}

.NoDisplay {
    display:none
}
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    margin-top: 10px;
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdownContent a {
    font-size: 11px !important;
    color: black;
    padding: 20px 16px;
    text-decoration: none;
    display: block;
    font-weight: normal !important;

}
.dropdownContent a:hover {
    background-color: #ddd;
    font-weight: bold !important;
}

.dropdown:hover .dropdownContent {display: block;}

/*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/