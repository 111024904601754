 i{
    color:#677d8f
}
/* .AboutUs h2,h3,h4{
    color: white !important
} */
.AboutUs {
    width:350px;
    box-sizing: border-box;
    
}
.AboutUs p{
    color:#677d8f;
    text-align: justify;
    font-size: 14px;
    line-height: 1.643;
    font-family: Roboto, sans-serif;
    font-weight: normal;
}

.AboutUs i{
    padding-right: 20px;
    font-size:24px;
}
@media only screen and  (max-width:1100px){
    .AboutUs{
        padding-left: 30px;
        padding-bottom: 30px;
     }
}