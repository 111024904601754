

.Checkbox ul {
    text-decoration: none;
    padding: 0;
    list-style-type: none;
}

.Checkbox ul li {
    display: flex;
    flex-direction: column-reverse;
}

.Checkbox ul li div input {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    position: relative;
    top: -3px;
    background-color: #79787f;
}


.Checkbox ul li div label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.6;
    font-family: Open Sans, sans-serif;
    letter-spacing: 0px;
    text-align: left;
    color: #79787f;
}

.Checkbox ul li div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
