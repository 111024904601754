.Copyright {
    widows: 100%;
    height: 40px;
    background-color: #fff;
}

.CopyrightText {
    margin: auto;
    padding: 10px;
    max-width: 1180px;
    color: '#677d8f';
    font-size: 12px;
    font-family: Roboto, sans-serif;
}

.CopyrightText span {
    color: #677d8f;

}

.CopyrightText span a {
    text-decoration: none;
    color: inherit;
}

@media only screen and  (max-width: 1100px) {
    .CopyrightText {
        text-align: center;
        margin: 0px 20px;
        padding: 8px;
    }
}