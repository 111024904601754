.Toolbar {
    width: 100%;
    /* display: flex; */
    background-color: white;
    /* justify-content: space-around; */
    /* position: fixed; */
    display: inline-block;
    top: 0;
    left: 0;
    height: 80px;
    z-index: 199;
    margin: 10px 20px; 
    /*padding: 0 20px;*/
    text-align: center;
    align-items: center;
}
.Menu{
    display: none;
}
@media (max-width: 700px){
    .Toolbar{
        flex-direction: row-reverse;
        background-color: #232C3D;
        margin: 0

    }
    .DisplayOnlyDesktop {
        display: none;
    }
    .Menu{
        display: block;
    }
    .Nav{
        display: none;
    }
    .Nav a {
        display: none;
    }
}
.Nav {
    display: inline-block;
    padding: 30px;
    width: 50%;
}

.Nav a {
    padding: 10px 14px;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    line-height: 2em;
    text-decoration: none;
    letter-spacing: 0.04em;
    color: #1e1d24;
    font-weight: 700;
    font-size: 15px;
}

.Nav a:hover {
    color: #08388a;
    font-weight: bolder;
}

@media (max-width: 700px) {
    .Nav {
        display: flex;
        flex-direction: column;
    }
    .Nav a {
        color:red
    }
    /* .Nav {
        display: none;
    } */
}

.NoDisplay {
    display:none
}
.Logo {
    width: 30%;
    background-color: white;
    height: 100%;
    padding: 5px;
    float:left;

}
.Logo img {
    height: 100%;
    width: 150px;
}

@media (max-width:700px){
    .Logo {
        display: none;
    }
}