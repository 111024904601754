.Info{
    width:350px;
    margin-left: 100px;

}
.Info h3{
    color:#677d8f;

}
.Info span{
color:#677d8f;
line-height: 1.643;
font-family: Roboto, sans-serif;
text-decoration: none; 
font-size:14px;
font-weight: normal;

}
.Info p{
    color:#677d8f;

}
.Info ul{
    list-style: none;
    padding-left:0;
}
.Info li{
   margin: 5px 0;
}
.Info li a{ 
text-decoration: none; 
color: inherit;
}
.Info i{
    margin-right: 10px;
    color:#677d8f;
    font-size:25px;
 }
 @media only screen and  (max-width:1100px){
    .Info{
        padding-left: 30px;
        margin-left: 0;

    }
}